<template>
  <v-flex fluid>
    <h3 class="my-5">Ventas de Casillero</h3>
    <v-simple-table>
      <thead>
        <th>Codigo</th>
        <th>Descripción</th>
        <th>Monto</th>
      </thead>
      <tbody>
        <tr v-for="qs in quoteSales" :key="qs.id">
          <td class="code">{{ qs.code }}</td>
          <td class="descripcion">{{ qs.description }}</td>
          <td class="monto">
            <v-text-field
              min="0"
              prefix="$."
              v-model="qs.monto"
              type="number"
            ></v-text-field>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-flex>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "QuoteSalesComponent",
  data() {
    return {};
  },
  computed: {
    ...mapState(["quoteSales"]),
  },
};
</script>

<style scoped>
.code {
  min-width: 20 !important;
}
.descripcion {
  min-width: 80% !important;
}
.monto {
  width: auto !important;
}
</style>
